<template>
  <div>
      <CRow>
          <CCard class="col-xl-12 padding-0">
              <CCardBody class="text-center">
                <CRow class="margin-0">
                  <CCol xl="12" class="padding-0 mb-4"><img :src="url" class="center" width="15%" alt="License"/></CCol>
                  <CCol xl="12" class="padding-0 mb-2"><span>MAXIMA INTEGRA Pty. Ltd.</span></CCol>
                  <CCol xl="12" class="padding-0 mb-2"><span>https://www.maximaintegra.com.au/</span></CCol>
                  <CCol xl="3" class="padding-0 mb-2"></CCol>
                  <CCol xl="3" class="padding-0 mb-2"><span>Cirrus-Pro</span></CCol>
                  <CCol xl="3" class="padding-0 mb-2"><span>CRRS-CPRO-NJSD-8K8H-HJ1J-NF3A</span></CCol>
                  <CCol xl="3" class="padding-0 mb-2"></CCol>
                </CRow>
              </CCardBody>
          </CCard>
      </CRow>
  </div>
</template>

<script>
export default {
  name: 'License',
  components: {},
  data () {
    return {
        url: 'img/company/cirrus.png',
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
